import Error from 'next/error';

class CustomError extends Error {
    static async getInitialProps(ctx) {
        const errorProps = await Error.getInitialProps(ctx);

        return Object.assign(errorProps, {
            namespacesRequired: ['common'],
        });
    }
}

export default CustomError;
